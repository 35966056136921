import React, { useState } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import tw from 'twin.macro';
import { css } from "styled-components/macro"; //eslint-disable-line
import chevronDownIcon from 'feather-icons/dist/icons/chevron-down.svg';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { SectionHeading, Subheading as SubheadingBase } from '../Misc/Headings';
import { SectionDescription } from '../Misc/Typography';
import { Container, ContentWithPaddingXl } from '../Misc/Layouts';
import svgDecoratorBlob1 from '../../images/svg-decorator-blob-7.svg';
import svgDecoratorBlob2 from '../../images/svg-decorator-blob-8.svg';

const ChevronDownIcon = () => (
    <img src={chevronDownIcon} alt="" />
);
const Subheading = tw(SubheadingBase)`mb-4 text-center`;
const Heading = tw(SectionHeading)`w-full text-center`;
const Description = tw(SectionDescription)`text-center`;

const Column = tw.div`flex flex-col items-center`;
const HeaderContent = tw.div``;

const FAQSContainer = tw.dl`mt-12 max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = motion.custom(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion.custom(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);

const DecoratorBlob1 = () => (
    <img src={svgDecoratorBlob1} tw="pointer-events-none -z-20 absolute right-0 top-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400" alt="" />
);
const DecoratorBlob2 = () => (
    <img src={svgDecoratorBlob2} tw="pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500" alt="" />
);

const Wrapper = styled.div`
  position: relative;
  padding: 0 2rem 0 2rem;
`;

export default () => {
    const { t } = useTranslation();
    const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

    const faqs = [
        {
            question: t('faqs.cards.1.question'),
            answer: t('faqs.cards.1.answer'),
        },
        {
            question: t('faqs.cards.2.question'),
            answer: t('faqs.cards.2.answer'),
        },
        {
            question: t('faqs.cards.3.question'),
            answer: t('faqs.cards.3.answer'),
        },
    ];

    const toggleQuestion = (questionIndex) => {
        if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
        else setActiveQuestionIndex(questionIndex);
    };

    return (
        <Wrapper>
            <Container>
                <ContentWithPaddingXl>
                    <Column>
                        <HeaderContent>
                            <Subheading>{t('faqs.subheading')}</Subheading>
                            <Heading>{t('faqs.heading')}</Heading>
                            <Description>{t('faqs.description')}</Description>
                        </HeaderContent>
                        <FAQSContainer>
                            {faqs.map((faq, index) => (
                                <FAQ
                                    key={index}
                                    onClick={() => {
                                        toggleQuestion(index);
                                    }}
                                    className="group"
                                >
                                    <Question>
                                        <QuestionText>{faq.question}</QuestionText>
                                        <QuestionToggleIcon
                                            variants={{
                                                collapsed: { rotate: 0 },
                                                open: { rotate: -180 },
                                            }}
                                            initial="collapsed"
                                            animate={activeQuestionIndex === index ? 'open' : 'collapsed'}
                                            transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
                                        >
                                            <ChevronDownIcon />
                                        </QuestionToggleIcon>
                                    </Question>
                                    <Answer
                                        variants={{
                                            open: { opacity: 1, height: 'auto', marginTop: '16px' },
                                            collapsed: { opacity: 0, height: 0, marginTop: '0px' },
                                        }}
                                        initial="collapsed"
                                        animate={activeQuestionIndex === index ? 'open' : 'collapsed'}
                                        transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                                    >
                                        {faq.answer}
                                    </Answer>
                                </FAQ>
                            ))}
                        </FAQSContainer>
                    </Column>
                </ContentWithPaddingXl>
            </Container>
        </Wrapper>
    );
};
