import React from 'react';
import tw from 'twin.macro';
import { css } from "styled-components/macro"; //eslint-disable-line
import { useTranslation } from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import { SectionHeading, Subheading as SubheadingBase } from '../Misc/Headings';
import { Container as ContainerBase, ContentWithPaddingXl } from '../Misc/Layouts';
import { SectionDescription } from '../Misc/Typography';

const Container = tw(ContainerBase)`my-8 lg:my-10 bg-primary-900 text-gray-100 -mx-8 px-8`;
const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)`sm:text-3xl md:text-4xl lg:text-5xl`;
const Subheading = tw(SubheadingBase)`text-gray-100 text-center`;
const Description = tw(SectionDescription)`text-gray-400 text-center mx-auto max-w-screen-md`;
const Introduction = tw(SectionDescription)`mt-16 text-white text-center mx-auto max-w-screen-md`;

const StatsContainer = tw.div`flex flex-col sm:flex-row items-center justify-center flex-wrap max-w-screen-md justify-between mx-auto`;
const Stat = tw.div`flex flex-col text-center p-4 tracking-wide`;
const StatKey = tw.div`text-xl font-medium`;
const StatValue = tw.div`text-4xl sm:text-3xl md:text-4xl lg:text-5xl font-black`;

const Wrapper = styled.div`
  position: relative;
  padding: 0 2rem 0 2rem;
`;

export default () => {
    const { t } = useTranslation();
    const stats = [
        {
            key: t('stats.cards.1.label'),
            value: t('stats.cards.1.value'),
        },
        {
            key: t('stats.cards.2.label'),
            value: t('stats.cards.2.value'),
        },
        {
            key: t('stats.cards.3.label'),
            value: t('stats.cards.3.value'),
        },
    ];

    return (
        <Wrapper>
            <Container>
                <ContentWithPaddingXl>
                    <HeadingContainer>
                        <Subheading>{t('stats.subheading')}</Subheading>
                        <Heading>{t('stats.heading')}</Heading>
                        <Description>{t('stats.description')}</Description>
                    </HeadingContainer>
                    <Introduction>{t('stats.introduction')}</Introduction>
                    <StatsContainer>
                        {stats.map((stat, index) => (
                            <Stat key={index}>
                                <StatValue>{stat.value}</StatValue>
                                <StatKey>{stat.key}</StatKey>
                            </Stat>
                        ))}
                    </StatsContainer>
                </ContentWithPaddingXl>
            </Container>
        </Wrapper>
    );
};
