import React, { useState } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from "styled-components/macro"; //eslint-disable-line
import { useTranslation } from 'gatsby-plugin-react-i18next';
import svgDecoratorBlob1 from '../../images/svg-decorator-blob-6.svg';
import svgDecoratorBlob2 from '../../images/svg-decorator-blob-7.svg';
import { SectionHeading, Subheading as SubheadingBase } from '../Misc/Headings';
import { SectionDescription } from '../Misc/Typography';
import { PrimaryButton as PrimaryButtonBase } from '../Misc/Buttons';
import { Container, ContentWithPaddingXl } from '../Misc/Layouts';
import Header from '../Layout/Header';

const HeaderContainer = tw.div`w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const PlansContainer = tw.div`flex justify-center flex-col md:flex-row items-center md:items-start relative`;
const Plan = styled.div`
  ${tw`w-full max-w-72 mt-16 md:mr-12 md:last:mr-0 text-center px-8 rounded-lg relative text-gray-900 bg-white flex flex-col shadow-raised`}

  ${(props) => props.featured
    && css`
      ${tw`border-2 border-gray-200 shadow-none`}
    `}
`;

const PlanHeader = styled.div`
  ${tw`flex flex-col leading-relaxed py-8 -mx-8 bg-gray-100 rounded-t-lg`}
  .name {
    ${tw`font-bold text-xl`}
  }
  .price {
    ${tw`font-bold text-4xl sm:text-5xl my-1`}
  }
  .slash {
    ${tw`text-xl text-gray-500`}
  }
  .duration {
    ${tw`lowercase text-gray-500 font-medium tracking-widest`}
  }
  .mainFeature {
    ${tw`text-gray-500 text-sm font-medium tracking-wide`}
  }
`;
const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-8 flex-1 text-sm`}
  .feature {
    ${tw`mt-5 first:mt-0 font-semibold text-gray-500`}
  }
`;

const PlanAction = tw.div`px-4 pb-8`;
const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`rounded-full tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
`;

const DecoratorBlob1 = () => (
    <img src={svgDecoratorBlob1} tw="pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-2/3 -translate-y-1/2" alt="" />
);

const DecoratorBlob2 = () => (
    <img src={svgDecoratorBlob2} tw="pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-25 transform translate-x-2/3 translate-y-1/2 fill-current text-teal-300" alt="" />
);

const Wrapper = styled.div`
  position: relative;
  padding: 2rem 2rem 0 2rem;
`;

export default ({
    primaryButtonUrl = 'https://center.lunaar.app/',
}) => {
    const { t } = useTranslation();
    const plans = [
        {
            name: t('pricing.cards.1.name'),
            durationPrices: [t('pricing.cards.1.price')],
            mainFeature: t('pricing.cards.1.description'),
            unit: t('pricing.cards.1.unit'),
            features: [
                t('pricing.cards.1.features.1'),
                t('pricing.cards.1.features.2'),
                t('pricing.cards.1.features.3'),
                t('pricing.cards.1.features.4'),
                t('pricing.cards.1.features.5'),
            ],
            featured: true,
        },
    ];

    return (
        <Wrapper>
            <Header />
            <Container>
                <ContentWithPaddingXl>
                    <HeaderContainer>
                        <Subheading>{t('pricing.subheading')}</Subheading>
                        <Heading>{t('pricing.heading')}</Heading>
                        <Description>{t('pricing.description')}</Description>
                    </HeaderContainer>
                    <PlansContainer>
                        {plans.map((plan, index) => (
                            <Plan key={index} featured={plan.featured}>
                                <PlanHeader>
                                    <span className="priceAndDuration">
                                        <span className="price">{plan.durationPrices[0]}</span>
                                        <span className="slash"> / </span>
                                        <span className="duration">{plan.unit}</span>
                                    </span>
                                    <span className="name">{plan.name}</span>
                                    <span className="mainFeature">{plan.mainFeature}</span>
                                </PlanHeader>
                                <PlanFeatures>
                                    {plan.features.map((feature, index) => (
                                        <span key={index} className="feature">
                                            {feature}
                                        </span>
                                    ))}
                                </PlanFeatures>
                                <PlanAction>
                                    <BuyNowButton as="a" href={primaryButtonUrl}>{t('pricing.button')}</BuyNowButton>
                                </PlanAction>
                            </Plan>
                        ))}
                    </PlansContainer>
                </ContentWithPaddingXl>
            </Container>
        </Wrapper>
    );
};
