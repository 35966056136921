import React from 'react';
import './style.css';
import 'tailwindcss/dist/base.css';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Pricing from '../components/Content/Pricing';
import Footer from '../components/Layout/Footer';
import FAQ from '../components/Content/FAQ';
import AnimationRevealPage from '../helpers/AnimationRevealPage';
import Stats from '../components/Content/Stats';
import Helmet from '../components/Layout/Helmet';

export default function PricingPage() {
    const { t } = useTranslation();
    return (
        <>
            <Helmet title={t('helmet.pricing.title')} desc={t('helmet.pricing.description')} meta={t('helmet.pricing.meta')} />
            <AnimationRevealPage disabled>
                <Pricing />
                <Stats />
                <FAQ />
                <Footer />
            </AnimationRevealPage>
        </>
    );
}
