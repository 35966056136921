import React from 'react';
import PropTypes from 'prop-types';
import { Helmet as I18nextHelmet } from 'gatsby-plugin-react-i18next';
import favicon from '../../images/favicon.ico';

const Helmet = ({
    title, desc, meta, children,
}) => (
    <I18nextHelmet
      link={[
            { rel: 'shortcut icon', type: 'image/ico', href: `${favicon}` },
        ]}
    >
        <title>{title}</title>
        <meta name="google-site-verification" content="rRfsfVDXLIju3OlVgucxioqo1riXyKuBauFgf0AgR3I" />
        <meta name="description" content={desc} />
        <meta property="og:title" content={meta} />
        <meta property="og:description" content={desc} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://i.postimg.cc/pPGJSKfy/OG-Image.png" />
        <meta property="twitter:image" content="https://i.postimg.cc/pPGJSKfy/OG-Image.png" />
        {children}
    </I18nextHelmet>
);

Helmet.defaultProps = {
    children: null,
};

Helmet.propTypes = {
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    meta: PropTypes.string.isRequired,
    children: PropTypes.element,
};

export default Helmet;
